@import url("typeface-roboto");

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
}

*::-webkit-scrollbar {
  width: min(0.5vw, 1rem);
}
*::-webkit-scrollbar-track {
  background: transparent;
}
*::-webkit-scrollbar-thumb {
  border-radius: min(0.25vw, 0.5rem);
  background: lightgray;
  box-shadow: inset 0 0 1px 1px lightgray;
  margin-top: 5rem;
}

html {
  scroll-behavior: smooth;

  height: 100%;
}

body {
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.MuiLink-root {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;

  color: #666;
}

.MuiLink-root:hover,
.MuiLink-root:focus {
  color: black;
}
